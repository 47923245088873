import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  clear(event) {
    const form = this.element
    const elements = form.querySelectorAll("input, select, textarea")

    elements.forEach(element => {
      if (element.tagName.toLowerCase() === 'select') {
        element.selectedIndex = 0
      } else if (element.type === 'checkbox' || element.type === 'radio') {
        element.checked = false
      } else {
        element.value = ''
      }
    })
    const clear_field = document.getElementById('clear_filters')
    clear_field.value = true
    
    form.requestSubmit()
  }
}
