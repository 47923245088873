import mapboxgl from "mapbox-gl";

export function createMap({
  container,
  style = "mapbox://styles/mapbox/light-v11",
  mapboxToken,
  mapCenter,
  zoom = 11.5,
  addNavigationControls = true,
}) {
  mapboxgl.accessToken = mapboxToken;

  const map = new mapboxgl.Map({
    container: container,
    style: style,
    zoom: zoom,
    center: mapCenter,
  });

  if (addNavigationControls) {
    map.addControl(new mapboxgl.NavigationControl());
  }

  return map;
}

export function addLayer(map, id, type, data, options = {}, sourceId = id) {
  if (data) {
    map.addSource(sourceId, { type: 'geojson', data });
  }
  map.addLayer({
    id: id,
    type,
    source: sourceId,
    ...options
  });

  // Initialize visibility state
  setLayerVisibility(map, id, 'visible');
}

export function setLayerVisibility(map, layerId, visibility = "visible") {
  map.setLayoutProperty(layerId, "visibility", visibility);
}

export function toggleLayerVisibility(map, layerId) {
  const visibility = map.getLayoutProperty(layerId, 'visibility');
  const newVisibility = visibility === 'visible' ? 'none' : 'visible';
  setLayerVisibility(map, layerId, newVisibility);
}

export function createPopup(map, coordinates, htmlContent) {
  new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(htmlContent)
    .addTo(map);
}

export function setupClickHandlers(map, handlers) {
  handlers.forEach(({ layer, handler }) => {
    map.on("click", layer, handler);
  });
}

export function setupHoverCursor(map, layers) {
  layers.forEach(layer => {
    map.on("mouseenter", layer, () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", layer, () => {
      map.getCanvas().style.cursor = "";
    });
  });
}

export function createToggleButton({ map, id, color, title, parent, layerId }) {
  const link = document.createElement('a');
  link.id = id;
  link.href = '#';
  link.innerHTML = `
    <div style="height:10px; width: 10px; background-color:${color}; border-radius:50%; margin-right:5px; opacity: 0.7;"></div>${title}`;
  link.className = 'flex items-center justify-center bg-gray-200 px-2 py-1.5 rounded-md border-2 border-gray-300 shadow-lg text-sm text-gray-700';

  parent.appendChild(link);

  // Initialize visibility state
  const visibility = map.getLayoutProperty(layerId, 'visibility') || 'visible';
  setLayerVisibility(map, layerId, visibility);

  if (visibility === 'visible') {
    link.classList.add('active');
  }

  // Add event listener for toggling layer visibility
  link.onclick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    toggleLayerVisibility(map, layerId);

    const newVisibility = map.getLayoutProperty(layerId, 'visibility');
    link.classList.toggle('active', newVisibility === 'visible');
  };

  return link;
}

export function fitToShape(map, coordinates, padding = 100) {
  if (!coordinates || coordinates.length === 0) {
    console.error("No coordinates provided to fitToShape");
    return;
  }

  // Create a 'LngLatBounds' with the first coordinate.
  const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  coordinates.forEach(coord => bounds.extend(coord));

  map.fitBounds(bounds, { padding: padding });
}

export function showLegend(colorScale) {
  const legend = document.getElementById('legend');
  legend.classList.remove("hidden")
  // remove the existing legend items
  legend.innerHTML = '';

  for (let i = 0; i < colorScale.length; i += 2) {
    const value = colorScale[i];
    const color = colorScale[i + 1];

    const legendItem = document.createElement('div');
    legendItem.className = 'legend-item';

    const colorBox = document.createElement('span');
    colorBox.className = 'color-box';
    colorBox.style.backgroundColor = color;

    const label = document.createElement('span');
    label.className = 'legend-label';
    label.textContent = `${value}`;

    legendItem.appendChild(colorBox);
    legendItem.appendChild(label);
    legend.appendChild(legendItem);
  }

  return legend;
}

export function addOverlay(map, layer, attribute, colorScale) {
  
  map.setPaintProperty(layer, 'fill-color', [
    'interpolate',
    ['linear'],
    ['get', attribute],
    ...colorScale
  ]);

  map.setPaintProperty(layer, 'fill-opacity', 0.75);
  showLegend(colorScale);
}