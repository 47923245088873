import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slideOver"];
  static outlets = ["ridings-canvass"];

  connect() {
    this.showSlideOver();
  }

  disconnect() {
  }

  close() {
    this.hideSlideOver();
  }

  showSlideOver() {
    // if (this.slideOverTarget.classList.contains("translate-x-full")) {
    //   this.slideOverTarget.classList.add("translate-x-full");
    // }
    this.slideOverTarget.classList.add("translate-x-full");

    setTimeout(() => {
      this.slideOverTarget.classList.remove("translate-x-full");
      this.slideOverTarget.classList.add("translate-x-0");
      
    }, 100);
    
    setTimeout(() => {
      this.ridingsCanvassOutlet.element.classList.add("max-w-canvass")
    }, 200);
  }

  hideSlideOver() {
    // if (this.slideOverTarget.classList.contains("translate-x-0")) {
    //   this.slideOverTarget.classList.remove("translate-x-0");
    //   this.slideOverTarget.classList.add("translate-x-full");
    // }
    this.slideOverTarget.classList.remove("translate-x-0");
    this.slideOverTarget.classList.add("translate-x-full");
    this.ridingsCanvassOutlet.element.classList.remove("max-w-canvass")

    setTimeout(() => {
      this.element.remove();
    }, 500)
  }
}