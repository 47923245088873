// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import Alpine from 'alpinejs'

Alpine.store('filters', { open: false })
Alpine.store('visible_column_filters', { open: false })
window.Alpine = Alpine

Alpine.start()

import LocalTime from "local-time"
require("local-time").start()
import "trix"
import "@rails/actiontext"
