import { Controller } from "@hotwired/stimulus";
import { createMap, addLayer, setLayerVisibility, toggleLayerVisibility, createPopup, setupClickHandlers, setupHoverCursor, createToggleButton, fitToShape, showLegend, addOverlay } from "../helpers/map_helpers";
import mapboxgl from 'mapbox-gl';

export default class extends Controller {
  static values = {
    ridingBoundary: Object,
    homeCentres: Object,
    pollingLocations: Object,
    pollBoundaries: Object,
    mapboxToken: String,
    mapCenter: Array,
    highlightPoll: Number,
  };

  // used to prevent multiple popups from opening
  eventHandled = false;

  connect() {
    this.map = createMap({
      container: this.element,
      mapboxToken: this.mapboxTokenValue,
      mapCenter: this.mapCenterValue,
      style: 'mapbox://styles/mapbox/light-v11',
      zoom: 11.5, // Adjust the zoom level as needed
      addNavigationControls: true, // Pass true or false based on your requirements
    });

    this.map.on('load', () => {
      this.initializeMapLayers();
      // this.setupPollingLocationFilter();
      this.setupOverlayFilter();
      this.setupEventHandlers();
      this.setupEscapeKeyHandler();
      this.setupCursorChangeHandlers();
    });

    const coordinates = this.ridingBoundaryValue.geometry.coordinates[0];
    fitToShape(this.map, coordinates, 50);
  }

  initializeMapLayers() {
    addLayer(this.map, "riding-layer", "line", this.ridingBoundaryValue, {
      paint: { "line-color": "#4338ca", "line-width": 5 },
    });

    addLayer(this.map, "polls-layer", "line", this.pollBoundariesValue, {
      paint: { "line-width": 2, "line-color": "#1e1b4b" },
    });

    addLayer(this.map, "polls-fill-layer", "fill", this.pollBoundariesValue, {
      paint: { "fill-color": "transparent" },
    });

    addLayer(this.map, "poll-labels", "symbol", this.pollBoundariesValue, {
      layout: {
        "text-field": ["get", "POLL_NUMBER"],
        "text-font": ["Lato Bold", "Open Sans Bold", "Arial Unicode MS Bold"],
        "text-size": 14,
      },
      paint: { "text-color": "#1e1b4b" },
    });

    addLayer(this.map, "poll-highlight-layer", "line", this.pollBoundariesValue, {
      paint: { "line-width": 2, "line-color": "transparent" },
    });

    // this.addZoneLayers();
    // this.addZoneToggleButtons();
    this.addLayerToggleButtons();

    // highlight poll if a value is provided
    if (this.highlightPollValue) {
      this.highlightPoll([this.highlightPollValue]);
    }
  }

  addZoneLayers() {
    this.zonesValue.forEach(zone => {
      const { number, color } = zone.metadata;
      const layerId = `zone-${number}-layer`;
      const sourceId = `zone-${number}`;
      addLayer(this.map, layerId, 'fill', zone, {
        paint: {
          'fill-color': color,
          'fill-opacity': 0.4,
          'fill-outline-color': '#000',
        },
      }, sourceId);
    });
  }

  addPollingLocationsLayer() {
    if (!this.pollingLocationsValue) return;

    this.map.loadImage(this.pollingLocationImageUrlValue, (error, image) => {
      if (error) throw error;

      this.map.addImage('custom-marker', image);
      addLayer(this.map, 'polling-locations-layer', 'symbol', this.pollingLocationsValue, {
        layout: {
          'icon-image': 'custom-marker',
          'icon-allow-overlap': true,
          'icon-anchor': 'top',
          'icon-size': 0.04,
        },
      }, 'polling-locations');
    });
  }

  addHomeCentresLayer() {
    if (!this.homeCentresValue) return;

    this.map.loadImage(this.homeCentreImageUrlValue, (error, image) => {
      if (error) throw error;

      this.map.addImage('home-centre-marker', image);
      addLayer(this.map, 'home-centres-layer', 'symbol', this.homeCentresValue, {
        layout: {
          'icon-image': 'home-centre-marker',
          'icon-allow-overlap': true,
          'icon-anchor': 'top',
          'icon-size': 0.06,
        },
      }, 'home-centres');
    });
  }

  setupEventHandlers() {
    const clickHandlers = [
      { layer: "home-centres-layer", handler: this.onHomeCentreClick.bind(this) },
      { layer: "polling-locations-layer", handler: this.onPollingLocationClick.bind(this) },
      { layer: "polls-fill-layer", handler: this.onPollClick.bind(this) },
    ];

    setupClickHandlers(this.map, clickHandlers);
  }

  setupCursorChangeHandlers() {
    const hoverableLayers = ["polling-locations-layer", "home-centres-layer"];
    setupHoverCursor(this.map, hoverableLayers);
  }

  onPollClick(event) {
    if (this.eventHandled) return;
    this.eventHandled = true;

    const coordinates = [event.lngLat.lng, event.lngLat.lat];
    const { POLL_NUMBER: poll_num, ids, doors, last_canvassed } = event.features[0].properties;

    console.log(event.features[0].properties);
    const htmlContent = `
        <div class="w-72">
          <div class="flex items-end">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 mr-2 text-indigo-600">
              <path fill-rule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" clip-rule="evenodd" />
            </svg>
            <h3 class="text-base font-medium -mb-1" style="font-family:'Inter';">
              Poll ${poll_num}
            </h3>
          </div>
          <div class="w-full">
            <div class="flow-root">
              <div class="-my-2 overflow-x-auto"> 
                <div class="inline-block min-w-full pt-2 align-middle">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th class="py-1 pl-3"></th>
                        <th class="px-3 py-1 text-left text-xs font-medium text-gray-900"></th>
                        <th class="px-3 py-1 text-left text-xs font-medium text-gray-900"></th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr>
                        <td class="py-2 pr-3 text-sm font-medium text-gray-900">Liberal IDs</td>
                        <td class="px-3 py-2 text-sm text-gray-500 text-right">${ids}</td>
                      </tr>
                      <tr>
                        <td class="py-2 pr-3 text-sm font-medium text-gray-900">Liberal Doors</td>
                        <td class="px-3 py-2 text-sm text-gray-500 text-right">${doors}</td>
                      </tr>
                      <tr>
                        <td class="py-2 pr-3 text-sm font-medium text-gray-900">Last Canvassed</td>
                        <td class="px-3 py-2 text-sm text-gray-500 text-right">${last_canvassed || 'Never'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;

    createPopup(this.map, coordinates, htmlContent);
    
    setTimeout(() => { this.eventHandled = false; }, 10);
  }

  setupPollingLocationFilter() {
    const pollingLocationFilter = document.getElementById('polling-location-filter');
    if (!pollingLocationFilter) {
      console.error("Polling location filter element is missing.");
      return;
    }

    pollingLocationFilter.addEventListener('change', (e) => {
      try {
        const rawValue = e.target.value.trim(); // Tri whitespace
        if (!rawValue) {
          this.filterPoll([]); // Pass an empty array if the value is empty
          return;
        }

        const values = JSON.parse(rawValue); // Attempt to parse the JSON value
        if (Array.isArray(values)) {
          this.filterPoll(values); // Only process if it's an array
        } else {
          console.warn("Polling location filter values should be an array. Received:", values);
          this.filterPoll([]); // Clear the filters as a fallback
        }
      } catch (error) {
        console.error("Error parsing polling location filter values:", error);
        this.filterPoll([]); // Clear the filters as a fallback
      }
    });
  }

  setupOverlayFilter() {
    const overlayFilter = document.getElementById('overlay-filter');
    if (!overlayFilter) {
      console.error("Overlay filter element is missing.");
      return;
    }

    overlayFilter.addEventListener('change', (e) => {
      try {
        const rawValue = e.target.value.trim();
        switch(rawValue) {
          case 'liberal_ids':
            this.liberalIdOverlay();
            break;
          case 'target_doors':
            this.targetDoorsOverlay();
            break;
          case 'tier1_doors':
            this.tier1DoorsOverlay();
            break;
          default:
            this.map.setPaintProperty('polls-fill-layer', 'fill-color', 'transparent');
            this.map.setPaintProperty('polls-fill-layer', 'fill-opacity', 1);

            const legend = document.getElementById('legend');
            if (!legend.classList.contains("hidden")) {
              legend.classList.add("hidden");
            }
        }
        try {
          this.addClearFiltersButton();
        } catch (error) {
          console.error("Error adding clear filters button:", error);
        }
        // this.addClearFiltersButton();
      } catch (error) {
        console.error("Error parsing overlay filter values:", error);
        this.map.setPaintProperty('polls-fill-layer', 'fill-color', 'transparent');
        this.map.setPaintProperty('polls-fill-layer', 'fill-opacity', 1);
      }
    });
  }

  filterPoll(values) {
    if (!Array.isArray(values) || values.length === 0 || values.every(v => v === "")) {
      this.map.setFilter('polls-layer', null);
      this.map.setFilter('poll-labels', null);
      this.map.setPaintProperty('polls-layer', 'line-color', "#1e1b4b");
      this.map.setPaintProperty('polls-layer', 'line-width', 2);

      this.zonesValue.forEach(zone => {
        this.map.setFilter(`zone-${zone.metadata.number}-layer`, null);
      });
    } else {
      this.map.setFilter('polls-layer', ['match', ['to-string', ['get', 'POLL_NUMBER']], values, true, false]);
      this.map.setPaintProperty('polls-layer', 'line-color', "red");
      this.map.setPaintProperty('polls-layer', 'line-width', 5);
      this.map.setFilter('poll-labels', ['match', ['to-string', ['get', 'POLL_NUMBER']], values, true, false]);

      this.zonesValue.forEach(zone => {
        this.map.setFilter(`zone-${zone.metadata.number}-layer`, ['match', ['to-string', ['get', 'POLL_NUMBER']], values, true, false]);
      });
    }
  }

  onHomeCentreClick(event) {
    if (this.eventHandled) return;
    this.eventHandled = true;

    const coordinates = event.features[0].geometry.coordinates.slice();
    const { title, zone, description } = event.features[0].properties;

    const htmlContent = `<h3 class="text-sm font-medium" style="font-family:'Inter';">🏠 ${title} - ${zone}</h3><p class="text-gray-500">${description}</p>`
    
    createPopup(this.map, coordinates, htmlContent);

    setTimeout(() => { this.eventHandled = false; }, 10);
  }

  onPollingLocationClick(event) {
    if (this.eventHandled) return;
    this.eventHandled = true;

    const coordinates = event.features[0].geometry.coordinates.slice();
    const { title, description } = event.features[0].properties;

    const htmlContent = `<h3 class="text-sm font-medium" style="font-family:'Inter';">🗳️ ${title}</h3><p class="text-gray-500">${description}</p>`;

    createPopup(this.map, coordinates, htmlContent);

    setTimeout(() => { this.eventHandled = false; }, 10);
  }

  addZoneToggleButtons() {
    const layersControl = document.getElementById('map-layers-control');
    if (!layersControl) {
      console.error("The 'map-layers-control' element is missing.");
      return;
    }

    const sortedZones = [...this.zonesValue].sort((a, b) => a.metadata.number - b.metadata.number);

    sortedZones.forEach(zone => {
      const { number, color, title } = zone.metadata;
      const layerId = `zone-${number}-layer`;

      // Skip if the button already exists
      if (document.getElementById(layerId)) {
        console.log(`Button for zone ${number} already exists.`);
        return;
      }

      createToggleButton({
        map: this.map,
        id: layerId,
        color,
        title,
        parent: layersControl,
        layerId,
      });
    });
  }

  addLayerToggleButtons() {
    const layersControl = document.getElementById('map-layers-control');
    if (!layersControl) {
      console.error("The 'map-layers-control' element is missing.");
      return;
    }

    Array.from(layersControl.getElementsByTagName('a'))
      .filter(link => !link.classList.contains('external_link'))
      .forEach(link => {
      link.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (link.dataset.layerGroup) {
          const layerGroupIds = link.dataset.layerGroup.split(', ');

          layerGroupIds.forEach(layerId => {
            toggleLayerVisibility(this.map, layerId);
          });

          link.classList.toggle('active');
        } else {
          const layerId = link.id;
          toggleLayerVisibility(this.map, layerId);
          link.classList.toggle('active');
        }
      };
    });
  }

  highlightPoll(values) {
    // Create a filter that matches all POLL_NUMBER except the ones in the values array
    const filter = ['match', ['get', 'POLL_NUMBER'], values, true, false];

    // Apply the filter to the poll-labels layer to highlight the selected polls
    this.map.setFilter('poll-highlight-layer', filter);

    // Set the text-opacity for the poll-labels layer based on the filter
    this.map.setPaintProperty('poll-labels', 'text-opacity', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], 1, // 100% opacity for matching polls
      0.3 // 30% opacity for non-matching polls
    ]);

    // Set the text-color for the poll-labels layer based on the filter
    this.map.setPaintProperty('poll-labels', 'text-color', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], '#d71920', // LPC red for matching polls
      '#1e1b4b' // not matching polls
    ]);

    // Set the text-color for the poll-labels layer based on the filter
    this.map.setPaintProperty('polls-fill-layer', 'fill-color', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], '#d71920', // LPC red for matching polls
      'transparent' // not matching polls
    ]);

    // Set the text-color for the poll-labels layer based on the filter
    this.map.setPaintProperty('polls-fill-layer', 'fill-opacity', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], 0.2, // 20% red for matching polls
      1 // full for not matching polls
    ]);

    // Highlight the polls in the values array
    this.map.setLayoutProperty('poll-highlight-layer', 'visibility', 'visible');
    this.map.setFilter('poll-highlight-layer', ['match', ['get', 'POLL_NUMBER'], values, true, false]);
    this.map.setPaintProperty('poll-highlight-layer', 'line-color', "#d71920");
    this.map.setPaintProperty('poll-highlight-layer', 'line-width', 4);

    // find the feature of the poll from the pollBoundariesValue
    const pollFeature = this.pollBoundariesValue.features.find(feature => values.includes(feature.properties.POLL_NUMBER));
    if (!pollFeature) {
      console.error("Poll feature not found in the pollBoundariesValue.");
      return;
    }

    const coordinates = pollFeature.geometry.coordinates[0];
    fitToShape(this.map, coordinates, 100);

    this.addClearFiltersButton();
  }

  clearHighlightedPolls() {
    this.map.setFilter('poll-highlight-layer', null);
    this.map.setLayoutProperty('poll-highlight-layer', 'visibility', 'none');
    this.map.setPaintProperty('poll-labels', 'text-opacity', 1);
    this.map.setPaintProperty('poll-labels', 'text-color', '#1e1b4b');
    this.map.setPaintProperty('polls-fill-layer', 'fill-color', 'transparent');
    this.map.setPaintProperty('polls-fill-layer', 'fill-opacity', 1);
  }

  addClearFiltersButton() {
    const layersControl = document.getElementById('map-layers-control');
    if (!layersControl) {
      console.error("The 'map-layers-control' element is missing.");
      return;
    }

    // Don't append multiple clear filters buttons
    if (document.getElementById('clear_filters')) {
      // console.log("Clear filters button already exists.");
      return;
    }

    // Clone the template content
    const template = document.getElementById('clearButtonTemplate');
    if (!template) {
      console.error("The 'clearButtonTemplate' element is missing.");
      return;
    }
    const clearButton = template.content.cloneNode(true).firstElementChild;
    layersControl.appendChild(clearButton);

    // add a click event listener to the clear filters button
    document.getElementById('clear_filters').addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.clearHighlightedPolls();
      this.removeClearHighlightButton();
      const overlayFilter = document.getElementById('overlay-filter');
      overlayFilter.value = '';

      const legend = document.getElementById('legend');
      if (!legend.classList.contains("hidden")) {
        legend.classList.add("hidden");
      }
    });
  }

  removeClearHighlightButton() {
    const clearButton = document.getElementById('clear_filters');
    if (clearButton) {
      clearButton.remove();
    }
  }

  setupEscapeKeyHandler() {
    // Add a listener for the "Escape" key to close all open popups
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        // Find all active popups on the map
        const popups = document.getElementsByClassName('mapboxgl-popup');

        // Remove each popup
        Array.from(popups).forEach(popup => popup.remove());
      }
    });
  }

  liberalIdOverlay() {
    const colorScale = [
      0, 'transparent',
      25, '#fecaca',
      50, '#f87171',
      75, '#ef4444',
      100, '#dc2626',
      125, '#b91c1c',
      150, '#991b1b',
      175, '#7f1d1d'
    ];

    addOverlay(this.map, 'polls-fill-layer', 'ids', colorScale);
  }

  targetDoorsOverlay() {
    const colorScale = [
      0, 'transparent',
      50, '#fecaca',
      100, '#f87171',
      150, '#ef4444',
      200, '#dc2626',
      250, '#b91c1c',
      300, '#991b1b',
      350, '#7f1d1d'
    ];

    addOverlay(this.map, 'polls-fill-layer', 'target_doors', colorScale);
  }

  tier1DoorsOverlay() {
    const colorScale = [
      0, 'transparent',
      20, '#fecaca',
      40, '#f87171',
      60, '#ef4444',
      80, '#dc2626',
      100, '#b91c1c',
      120, '#991b1b',
      140, '#7f1d1d'
    ];

    addOverlay(this.map, 'polls-fill-layer', 'tier1_doors', colorScale);
  }
}
