import { Controller } from "@hotwired/stimulus";
import { createMap, addLayer, setLayerVisibility, toggleLayerVisibility, createPopup, setupClickHandlers, setupHoverCursor, createToggleButton, fitToShape } from "../helpers/map_helpers";

export default class extends Controller {
  static values = {
    ridingBoundary: Object,
    pollBoundaries: Object,
    mapboxToken: String,
    mapCenter: Array,
    highlightPoll: Number,
  }; 

  // used to prevent multiple popups from opening
  eventHandled = false;

  connect() {
    this.map = createMap({
      container: this.element,
      mapboxToken: this.mapboxTokenValue,
      mapCenter: this.mapCenterValue,
      style: 'mapbox://styles/mapbox/light-v11',
      zoom: 14, // Adjust the zoom level as needed
      addNavigationControls: false,
    });

    // Make the map instance available globally for sidebar controller
    // window.mapInstance = this.map
    document.dispatchEvent(new CustomEvent("map:ready", { detail: { map: this.map } }))

    this.map.on('load', () => {
      this.initializeMapLayers();
      this.setupEventHandlers();
      this.setupEscapeKeyHandler();
      // this.setupCursorChangeHandlers();
    });
  }

  initializeMapLayers() {
    addLayer(this.map, "riding-layer", "line", this.ridingBoundaryValue, {
      paint: { "line-color": "#4338ca", "line-width": 5 },
    });

    addLayer(this.map, "polls-layer", "line", this.pollBoundariesValue, {
      paint: { "line-width": 2, "line-color": "#1e1b4b" },
    });

    addLayer(this.map, "poll-labels", "symbol", this.pollBoundariesValue, {
      layout: {
        "text-field": ["get", "POLL_NUMBER"],
        "text-font": ["Lato Bold", "Open Sans Bold", "Arial Unicode MS Bold"],
        "text-size": 14,
      },
      paint: { "text-color": "#1e1b4b" },
    });

    addLayer(this.map, "polls-fill-layer", "fill", this.pollBoundariesValue, {
      paint: { "fill-color": "transparent" },
    });

    addLayer(this.map, "poll-highlight-layer", "line", this.pollBoundariesValue, {
      paint: { "line-width": 2, "line-color": "transparent" },
    });

    // this.addLayerToggleButtons();

    this.highlightPoll([this.highlightPollValue]);
  }

  // setupEventHandlers() {
  //   const clickHandlers = [
  //     { layer: "polls-fill-layer", handler: this.onPollClick.bind(this) },
  //   ];

  //   setupClickHandlers(this.map, clickHandlers);
  // }

  // setupCursorChangeHandlers() {
  //   // const hoverableLayers = ["polling-locations-layer", "home-centres-layer"];
  //   // setupHoverCursor(this.map, hoverableLayers);
  // }

  onPollClick(event) {
    if (this.eventHandled) return;
    this.eventHandled = true;

    const coordinates = [event.lngLat.lng, event.lngLat.lat];
    const { POLL_NUMBER: poll_num, ids, doors, ids_remaining, doors_remaining, polling_location } = event.features[0].properties;

    const htmlContent = `
        <div class="w-72">
          <div class="flex items-end">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 mr-2 text-indigo-600">
              <path fill-rule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" clip-rule="evenodd" />
            </svg>
            <h3 class="text-base font-medium -mb-1" style="font-family:'Inter';">
              Poll ${poll_num}
            </h3>
          </div>
          <p class="pt-1 text-xs text-gray-500 ml-7">${polling_location || ''}</p>
          <div class="w-full">
            <div class="flow-root">
              <div class="-my-2 overflow-x-auto">
                <div class="inline-block min-w-full pt-2 align-middle">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th class="py-1 pl-3"></th>
                        <th class="px-3 py-1 text-left text-xs font-medium text-gray-900">Total</th>
                        <th class="px-3 py-1 text-left text-xs font-medium text-gray-900">Remaining</th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr>
                        <td class="py-2 pr-3 text-sm font-medium text-gray-900">Liberal IDs</td>
                        <td class="px-3 py-2 text-sm text-gray-500">${ids}</td>
                        <td class="px-3 py-2 text-sm text-gray-500">${ids_remaining}</td>
                      </tr>
                      <tr>
                        <td class="py-2 pr-3 text-sm font-medium text-gray-900">Liberal Doors</td>
                        <td class="px-3 py-2 text-sm text-gray-500">${doors}</td>
                        <td class="px-3 py-2 text-sm text-gray-500">${doors_remaining}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;

    createPopup(this.map, coordinates, htmlContent);
    
    setTimeout(() => { this.eventHandled = false; }, 10);
  }

  addLayerToggleButtons() {
    const layersControl = document.getElementById('map-layers-control');
    if (!layersControl) {
      console.error("The 'map-layers-control' element is missing.");
      return;
    }

    Array.from(layersControl.getElementsByTagName('a')).forEach(link => {
      link.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (link.dataset.layerGroup) {
          const layerGroupIds = link.dataset.layerGroup.split(', ');

          layerGroupIds.forEach(layerId => {
            toggleLayerVisibility(this.map, layerId);
          });

          link.classList.toggle('active');
        } else {
          const layerId = link.id;
          toggleLayerVisibility(this.map, layerId);
          link.classList.toggle('active');
        }
      };
    });
  }

  highlightPoll(values) {
    // Create a filter that matches all POLL_NUMBER except the ones in the values array
    const filter = ['match', ['get', 'POLL_NUMBER'], values, true, false];

    // Apply the filter to the poll-labels layer to highlight the selected polls
    this.map.setFilter('poll-highlight-layer', filter);

    // Set the text-opacity for the poll-labels layer based on the filter
    this.map.setPaintProperty('poll-labels', 'text-opacity', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], 1, // 100% opacity for matching polls
      0.3 // 30% opacity for non-matching polls
    ]);

    // Set the text-color for the poll-labels layer based on the filter
    this.map.setPaintProperty('poll-labels', 'text-color', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], '#d71920', // LPC red for matching polls
      '#1e1b4b' // not matching polls
    ]);

    // Set the text-color for the poll-labels layer based on the filter
    this.map.setPaintProperty('polls-fill-layer', 'fill-color', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], '#d71920', // LPC red for matching polls
      'transparent' // not matching polls
    ]);

    // Set the text-color for the poll-labels layer based on the filter
    this.map.setPaintProperty('polls-fill-layer', 'fill-opacity', [
      'case',
      ['in', ['get', 'POLL_NUMBER'], ['literal', values]], 0.2, // 20% red for matching polls
      1 // full for not matching polls
    ]);

    // Highlight the polls in the values array
    this.map.setFilter('poll-highlight-layer', ['match', ['get', 'POLL_NUMBER'], values, true, false]);
    this.map.setPaintProperty('poll-highlight-layer', 'line-color', "#d71920");
    this.map.setPaintProperty('poll-highlight-layer', 'line-width', 4);

    // find the feature of the poll from the pollBoundariesValue
    const pollFeature = this.pollBoundariesValue.features.find(feature => values.includes(feature.properties.POLL_NUMBER));
    if (!pollFeature) {
      console.error("Poll feature not found in the pollBoundariesValue.");
      return;
    }

    const coordinates = pollFeature.geometry.coordinates[0];
    fitToShape(this.map, coordinates, 50);
  }

  setupEscapeKeyHandler() {
    // Add a listener for the "Escape" key to close all open popups
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        // Find all active popups on the map
        const popups = document.getElementsByClassName('mapboxgl-popup');

        // Remove each popup
        Array.from(popups).forEach(popup => popup.remove());
      }
    });
  }
}
