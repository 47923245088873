import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "content", "expandIcon", "collapseIcon"]

  initialize() {
    this.expanded = false
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this)
    this.handleResize = this.handleResize.bind(this)
    window.Alpine.store('filters', { open: false })
  }

  connect() {
    document.addEventListener("map:ready", event => {
      this.map = event.detail.map
    })

    this.sidebarTarget.addEventListener('transitionend', this.handleTransitionEnd)
    window.addEventListener('resize', this.handleResize)
    this.isMobile = window.innerWidth < 640 // md breakpoint
    console.log("isMobile", this.isMobile)
  }

  disconnect() {
    this.sidebarTarget.removeEventListener('transitionend', this.handleTransitionEnd)
  }

  handleResize() {
    this.isMobile = window.innerWidth < 640
    if (this.expanded) {
      this.setExpandedState()
    }
  }

  expand() {
    if (!this.expanded) {
      this.toggle()
    }
  }

  toggle() {
    this.expanded = !this.expanded
    this.expanded ? this.showSidebar() : this.hideSidebar()
  }

  showSidebar() {
    this.sidebarTarget.classList.remove("hidden")

    requestAnimationFrame(() => {
      this.applyTransitionClasses()
      this.setExpandedState()

      this.expandIconTarget.classList.add("hidden")
      this.collapseIconTarget.classList.remove("hidden")
    })
  }

  hideSidebar() {
    this.applyTransitionClasses()
    this.setCollapsedState()

    // After animation, finalize hidden state
    setTimeout(() => {
      this.sidebarTarget.classList.add("hidden")
      this.restorePadding()
      this.removeTransitionClasses()
    }, 500)

    this.expandIconTarget.classList.remove("hidden")
    this.collapseIconTarget.classList.add("hidden")
  }

  // Called after transition ends
  handleTransitionEnd(event) {
    if (event.propertyName.includes('width')) {
      // If map is defined, force map to recalculate its size
      if (this.map && typeof this.map.resize === 'function') {
        this.map.resize()
      }
    }
  }

  // Helper Methods
  applyTransitionClasses() {
    this.contentTarget.classList.add("transition-all", "duration-500", "ease-in-out")
    this.sidebarTarget.classList.add("transition-all", "duration-500", "ease-in-out")
  }

  removeTransitionClasses() {
    this.contentTarget.classList.remove("transition-all", "duration-500", "ease-in-out")
    this.sidebarTarget.classList.remove("transition-all", "duration-500", "ease-in-out")
  }

  setExpandedState() {
    // Sidebar classes
    this.sidebarTarget.classList.remove("w-0", "translate-x-full", "opacity-0")
    
    if (this.isMobile) {
      this.sidebarTarget.classList.remove("w-2/5")
      this.sidebarTarget.classList.add("w-full")
      // Keep content full width on mobile
      this.contentTarget.classList.remove("w-full")
      this.contentTarget.classList.add("w-0")
    } else {
      this.sidebarTarget.classList.remove("w-full")
      this.sidebarTarget.classList.add("w-2/5")
      // Adjust content width on desktop
      this.contentTarget.classList.remove("w-full")
      this.contentTarget.classList.add("w-3/5")
    }

    // Adjust container padding
    this.element.classList.remove("lg:pr-8", "sm:pr-6", "pr-4")
    this.element.classList.add("lg:pr-2", "sm:pr-2", "pr-2")
  }

  setCollapsedState() {
    // Common collapse classes
    this.sidebarTarget.classList.add("w-0", "translate-x-full", "opacity-0")
    this.sidebarTarget.classList.remove("w-2/5", "w-full")
  
    if (this.isMobile) {
      // Mobile specific collapse
      this.contentTarget.classList.remove("w-0")
      this.contentTarget.classList.add("w-full")
    } else {
      // Desktop specific collapse
      this.contentTarget.classList.remove("w-3/5")
      this.contentTarget.classList.add("w-full")
    }
  }

  restorePadding() {
    this.element.classList.remove("lg:pr-2", "sm:pr-2", "pr-2")
    this.element.classList.add("lg:pr-8", "sm:pr-6", "pr-4")
  }
}
