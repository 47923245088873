import { Controller } from "@hotwired/stimulus"
import { computePosition, flip, shift, offset, autoUpdate } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ["trigger", "content"]
  
  connect() {
    // Create portal container if it doesn't exist
    this.portalContainer = document.getElementById('floating-ui-portal')
    if (!this.portalContainer) {
      this.portalContainer = document.createElement('div')
      this.portalContainer.id = 'floating-ui-portal'
      document.body.appendChild(this.portalContainer)
    }
    
    // Store original parent and content reference
    this.originalParent = this.contentTarget.parentElement
    // Store a persistent reference to the content element
    this.contentElement = this.contentTarget
  }

  mouseOver(event) {
    if (event) event.preventDefault()
    
    try {
      // Use stored reference instead of contentTarget
      if (this.contentElement.parentElement !== this.portalContainer) {
        this.portalContainer.appendChild(this.contentElement)
      }
      
      // Show content
      this.contentElement.classList.remove("hidden")
      
      // Set styles
      Object.assign(this.contentElement.style, {
        pointerEvents: 'auto',
        zIndex: '99999',
        width: '400px',  // Set fixed width
        maxWidth: '90vw' // Prevent overflow on small screens
      })

      
      // Setup floating UI
      this.cleanup = autoUpdate(
        this.triggerTarget,
        this.contentElement,
        () => {
          computePosition(this.triggerTarget, this.contentElement, {
            placement: 'bottom-start',
            middleware: [
              offset(8),
              flip({
                fallbackPlacements: ['bottom-start', 'bottom-end'],
              }),
              shift({padding: 5}),
            ],
          }).then(({x, y}) => {
            Object.assign(this.contentElement.style, {
              position: 'fixed',
              left: `${x}px`,
              top: `${y}px`,
            })
          })
        }
      )
    } catch (error) {
      console.error('Error in mouseOver:', error)
    }
  }

  mouseOut(event) {
    if (event) event.preventDefault()
    
    try {
      // Use stored reference instead of contentTarget
      this.contentElement.classList.add("hidden")
      
      // Reset styles
      Object.assign(this.contentElement.style, {
        position: '',
        left: '',
        top: '',
        zIndex: '',
        maxWidth: '',
        pointerEvents: ''
      })

      // Cleanup positioning
      if (this.cleanup) {
        this.cleanup()
        this.cleanup = null
      }
      
      // Move back to original location
      if (this.originalParent) {
        this.originalParent.appendChild(this.contentElement)
      }
    } catch (error) {
      console.error('Error in mouseOut:', error)
    }
  }

  disconnect() {
    if (this.cleanup) {
      this.cleanup()
    }
  }
}